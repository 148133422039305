import { getAgeFromDate } from '~/utils/date';

export const usePatientStore = defineStore('patient', () => {
  const $api = useApi();

  const allPatients = ref([]);
  const totalPatient = ref(0);
  const totalPages = ref(1);

  const activePatientId = ref(undefined);

  const activePatientConsultations = ref([]);
  const activePatientLastConsultation = ref(undefined);
  const activePatientFoodSurveys = ref([]);
  const activePatientLastFoodSurvey = ref(undefined);

  const activePatient = computed(() =>
    allPatients.value.find((p) => p.id === activePatientId.value),
  );
  const activePatientDisplayName = computed(() =>
    activePatient.value
      ? `${activePatient.value.firstname} ${activePatient.value.lastname}`
      : undefined,
  );
  const activePatientAge = computed(() =>
    getAgeFromDate(activePatient.value.date_of_birth),
  );

  function clearActivePatient() {
    activePatientConsultations.value = ref([]);
    activePatientLastConsultation.value = ref(undefined);
    activePatientFoodSurveys.value = ref([]);
    activePatientLastFoodSurvey.value = ref(undefined);
  }

  async function fetchAllPatients(data?: Object = {}) {
    const patients = await $api.post(`/patient/all`, data);
    if (patients.data) {
      allPatients.value = patients.data;
      totalPatient.value = patients.count;
      totalPages.value = patients.totalPages;
    }
  }

  async function fetchDetailedPatient(patientId: string) {
    const { data, error } = await $api.get(`/patient/${patientId}`);
    if (data) {
      const patientToUpdateIndex = allPatients.value.findIndex(
        (p) => p.id === patientId,
      );
      if (patientToUpdateIndex != -1) {
        allPatients.value[patientToUpdateIndex] = data;
      } else {
        allPatients.value.push(data);
      }
      setActivePatient(patientId);
    }
  }

  async function createPatient(data: Record<string, string>) {
    const createdPatient = await $api.post(`/patient`, data);
    if (createdPatient.data) {
      allPatients.value.push(createdPatient.data);
    }
  }

  async function updatePatient(
    patientId: string,
    data: Record<string, string>,
  ) {
    const patientUpdated = await $api.put(`/patient/${patientId}`, data);
    if (patientUpdated) {
      const patientToUpdateIndex = allPatients.value.findIndex(
        (p) => p.id === patientId,
      );
      allPatients.value[patientToUpdateIndex] = {
        ...allPatients.value[patientToUpdateIndex],
        ...patientUpdated.data,
      };
    }
  }

  async function updateActivePatient() {
    const patientUpdated = await $api.put(
      `/patient/${activePatientId.value}`,
      activePatient.value,
    );
    if (patientUpdated) {
      const patientToUpdateIndex = allPatients.value.findIndex(
        (p) => p.id === activePatientId.value,
      );
      allPatients.value[patientToUpdateIndex] = {
        ...allPatients.value[patientToUpdateIndex],
        ...patientUpdated.data,
      };
    }
  }

  async function deletePatient(patientId: string) {
    const patientDeleted = await $api.delete(`/patient/${patientId}`);
    if (patientDeleted) {
      allPatients.value = allPatients.value.filter((p) => p.id !== patientId);
    }
  }

  function setActivePatient(patientId: string) {
    activePatientId.value = patientId;
  }

  async function fetchActivePatientConsultations(data?: Object = {}) {
    const consultations = await $api.post(
      `/patient/${activePatientId.value}/consultation/all`,
      data,
    );
    if (consultations.data) {
      activePatientConsultations.value = consultations.data;
    }
  }

  async function fetchActivePatientLastConsultation() {
    const consultation = await $api.get(
      `/patient/${activePatientId.value}/consultation/last`,
    );
    activePatientLastConsultation.value = consultation.data;
  }

  async function fetchActivePatientFoodSurveys(data?: Object = {}) {
    const foodSurveys = await $api.post(
      `/patient/${activePatientId.value}/foodsurvey/all`,
      data,
    );
    if (foodSurveys.data) {
      activePatientFoodSurveys.value = foodSurveys.data;
    }
  }

  async function fetchActivePatientLastFoodSurvey() {
    const foodSurvey = await $api.get(
      `/patient/${activePatientId.value}/foodsurvey/last`,
    );
    activePatientLastFoodSurvey.value = foodSurvey.data;
  }

  return {
    allPatients,
    totalPatient,
    totalPages,
    activePatient,
    activePatientId,
    activePatientDisplayName,
    activePatientConsultations,
    activePatientLastConsultation,
    activePatientFoodSurveys,
    activePatientLastFoodSurvey,
    activePatientAge,
    createPatient,
    updatePatient,
    fetchAllPatients,
    fetchDetailedPatient,
    fetchActivePatientConsultations,
    fetchActivePatientLastConsultation,
    fetchActivePatientFoodSurveys,
    fetchActivePatientLastFoodSurvey,
    setActivePatient,
    updateActivePatient,
    clearActivePatient,
  };
});
