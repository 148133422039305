import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as authI5JGIXywJyMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as indexxIvUaO9ddgMeta } from "/vercel/path0/pages/backoffice/basefoods/[baseFoodId]/index.vue?macro=true";
import { default as index3bRQzOTbhhMeta } from "/vercel/path0/pages/backoffice/basefoods/index.vue?macro=true";
import { default as indexbrkoCrElG3Meta } from "/vercel/path0/pages/backoffice/commercialfoods/[commercialFoodId]/index.vue?macro=true";
import { default as indexBK4O0VnDz5Meta } from "/vercel/path0/pages/backoffice/commercialfoods/index.vue?macro=true";
import { default as createFoodyR3FdE88XPMeta } from "/vercel/path0/pages/backoffice/createFood.vue?macro=true";
import { default as foodfamilyK9suPMOT75Meta } from "/vercel/path0/pages/backoffice/foodfamily.vue?macro=true";
import { default as nutrientKbzafZToTXMeta } from "/vercel/path0/pages/backoffice/nutrient.vue?macro=true";
import { default as portionsWzlcN9mnGvMeta } from "/vercel/path0/pages/backoffice/portions.vue?macro=true";
import { default as index17AnEgfAHTMeta } from "/vercel/path0/pages/backoffice/recipefoods/[recipeId]/index.vue?macro=true";
import { default as indexMWDZKRt2rqMeta } from "/vercel/path0/pages/backoffice/recipefoods/index.vue?macro=true";
import { default as add5IDBSjW9HZMeta } from "/vercel/path0/pages/food-survey/add.vue?macro=true";
import { default as indexH5AaUUeSy6Meta } from "/vercel/path0/pages/onboarding/index.vue?macro=true";
import { default as _91analysisId_93e1cBuYbn71Meta } from "/vercel/path0/pages/patients/[patientId]/analysis/[analysisId].vue?macro=true";
import { default as index18WceqgcrWMeta } from "/vercel/path0/pages/patients/[patientId]/analysis/index.vue?macro=true";
import { default as lastw5c63QoU1PMeta } from "/vercel/path0/pages/patients/[patientId]/analysis/last.vue?macro=true";
import { default as new6loVLlB7zsMeta } from "/vercel/path0/pages/patients/[patientId]/analysis/new.vue?macro=true";
import { default as _91consultationId_938yd7BBCkvdMeta } from "/vercel/path0/pages/patients/[patientId]/consultations/[consultationId].vue?macro=true";
import { default as indexg3Ki5isTgUMeta } from "/vercel/path0/pages/patients/[patientId]/consultations/index.vue?macro=true";
import { default as lastvlH07Gmw8mMeta } from "/vercel/path0/pages/patients/[patientId]/consultations/last.vue?macro=true";
import { default as newNytUfOQ7MPMeta } from "/vercel/path0/pages/patients/[patientId]/consultations/new.vue?macro=true";
import { default as _91foodSurveyId_93meALH6rGP0Meta } from "/vercel/path0/pages/patients/[patientId]/food-survey/[foodSurveyId].vue?macro=true";
import { default as indexoI12ijbpMJMeta } from "/vercel/path0/pages/patients/[patientId]/food-survey/index.vue?macro=true";
import { default as last3jT7ioZn9UMeta } from "/vercel/path0/pages/patients/[patientId]/food-survey/last.vue?macro=true";
import { default as newcpK6U9BtzYMeta } from "/vercel/path0/pages/patients/[patientId]/food-survey/new.vue?macro=true";
import { default as indexUNGl4Z6FjnMeta } from "/vercel/path0/pages/patients/[patientId]/index.vue?macro=true";
import { default as objectiveshtYCjKQxgLMeta } from "/vercel/path0/pages/patients/[patientId]/objectives.vue?macro=true";
import { default as index7avJUh060AMeta } from "/vercel/path0/pages/patients/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "account___fr",
    path: "/fr/account",
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "account___pt",
    path: "/pt/account",
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "auth___en",
    path: "/en/auth",
    component: () => import("/vercel/path0/pages/auth.vue")
  },
  {
    name: "auth___fr",
    path: "/fr/auth",
    component: () => import("/vercel/path0/pages/auth.vue")
  },
  {
    name: "auth___pt",
    path: "/pt/auth",
    component: () => import("/vercel/path0/pages/auth.vue")
  },
  {
    name: "backoffice-basefoods-baseFoodId___en",
    path: "/en/backoffice/basefoods/:baseFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/basefoods/[baseFoodId]/index.vue")
  },
  {
    name: "backoffice-basefoods-baseFoodId___fr",
    path: "/fr/backoffice/basefoods/:baseFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/basefoods/[baseFoodId]/index.vue")
  },
  {
    name: "backoffice-basefoods-baseFoodId___pt",
    path: "/pt/backoffice/basefoods/:baseFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/basefoods/[baseFoodId]/index.vue")
  },
  {
    name: "backoffice-basefoods___en",
    path: "/en/backoffice/basefoods",
    component: () => import("/vercel/path0/pages/backoffice/basefoods/index.vue")
  },
  {
    name: "backoffice-basefoods___fr",
    path: "/fr/backoffice/basefoods",
    component: () => import("/vercel/path0/pages/backoffice/basefoods/index.vue")
  },
  {
    name: "backoffice-basefoods___pt",
    path: "/pt/backoffice/basefoods",
    component: () => import("/vercel/path0/pages/backoffice/basefoods/index.vue")
  },
  {
    name: "backoffice-commercialfoods-commercialFoodId___en",
    path: "/en/backoffice/commercialfoods/:commercialFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/commercialfoods/[commercialFoodId]/index.vue")
  },
  {
    name: "backoffice-commercialfoods-commercialFoodId___fr",
    path: "/fr/backoffice/commercialfoods/:commercialFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/commercialfoods/[commercialFoodId]/index.vue")
  },
  {
    name: "backoffice-commercialfoods-commercialFoodId___pt",
    path: "/pt/backoffice/commercialfoods/:commercialFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/commercialfoods/[commercialFoodId]/index.vue")
  },
  {
    name: "backoffice-commercialfoods___en",
    path: "/en/backoffice/commercialfoods",
    component: () => import("/vercel/path0/pages/backoffice/commercialfoods/index.vue")
  },
  {
    name: "backoffice-commercialfoods___fr",
    path: "/fr/backoffice/commercialfoods",
    component: () => import("/vercel/path0/pages/backoffice/commercialfoods/index.vue")
  },
  {
    name: "backoffice-commercialfoods___pt",
    path: "/pt/backoffice/commercialfoods",
    component: () => import("/vercel/path0/pages/backoffice/commercialfoods/index.vue")
  },
  {
    name: "backoffice-createFood___en",
    path: "/en/backoffice/createFood",
    component: () => import("/vercel/path0/pages/backoffice/createFood.vue")
  },
  {
    name: "backoffice-createFood___fr",
    path: "/fr/backoffice/createFood",
    component: () => import("/vercel/path0/pages/backoffice/createFood.vue")
  },
  {
    name: "backoffice-createFood___pt",
    path: "/pt/backoffice/createFood",
    component: () => import("/vercel/path0/pages/backoffice/createFood.vue")
  },
  {
    name: "backoffice-foodfamily___en",
    path: "/en/backoffice/foodfamily",
    component: () => import("/vercel/path0/pages/backoffice/foodfamily.vue")
  },
  {
    name: "backoffice-foodfamily___fr",
    path: "/fr/backoffice/foodfamily",
    component: () => import("/vercel/path0/pages/backoffice/foodfamily.vue")
  },
  {
    name: "backoffice-foodfamily___pt",
    path: "/pt/backoffice/foodfamily",
    component: () => import("/vercel/path0/pages/backoffice/foodfamily.vue")
  },
  {
    name: "backoffice-nutrient___en",
    path: "/en/backoffice/nutrient",
    component: () => import("/vercel/path0/pages/backoffice/nutrient.vue")
  },
  {
    name: "backoffice-nutrient___fr",
    path: "/fr/backoffice/nutrient",
    component: () => import("/vercel/path0/pages/backoffice/nutrient.vue")
  },
  {
    name: "backoffice-nutrient___pt",
    path: "/pt/backoffice/nutrient",
    component: () => import("/vercel/path0/pages/backoffice/nutrient.vue")
  },
  {
    name: "backoffice-portions___en",
    path: "/en/backoffice/portions",
    component: () => import("/vercel/path0/pages/backoffice/portions.vue")
  },
  {
    name: "backoffice-portions___fr",
    path: "/fr/backoffice/portions",
    component: () => import("/vercel/path0/pages/backoffice/portions.vue")
  },
  {
    name: "backoffice-portions___pt",
    path: "/pt/backoffice/portions",
    component: () => import("/vercel/path0/pages/backoffice/portions.vue")
  },
  {
    name: "backoffice-recipefoods-recipeId___en",
    path: "/en/backoffice/recipefoods/:recipeId()",
    component: () => import("/vercel/path0/pages/backoffice/recipefoods/[recipeId]/index.vue")
  },
  {
    name: "backoffice-recipefoods-recipeId___fr",
    path: "/fr/backoffice/recipefoods/:recipeId()",
    component: () => import("/vercel/path0/pages/backoffice/recipefoods/[recipeId]/index.vue")
  },
  {
    name: "backoffice-recipefoods-recipeId___pt",
    path: "/pt/backoffice/recipefoods/:recipeId()",
    component: () => import("/vercel/path0/pages/backoffice/recipefoods/[recipeId]/index.vue")
  },
  {
    name: "backoffice-recipefoods___en",
    path: "/en/backoffice/recipefoods",
    component: () => import("/vercel/path0/pages/backoffice/recipefoods/index.vue")
  },
  {
    name: "backoffice-recipefoods___fr",
    path: "/fr/backoffice/recipefoods",
    component: () => import("/vercel/path0/pages/backoffice/recipefoods/index.vue")
  },
  {
    name: "backoffice-recipefoods___pt",
    path: "/pt/backoffice/recipefoods",
    component: () => import("/vercel/path0/pages/backoffice/recipefoods/index.vue")
  },
  {
    name: "food-survey-add___en",
    path: "/en/food-survey/add",
    meta: add5IDBSjW9HZMeta || {},
    component: () => import("/vercel/path0/pages/food-survey/add.vue")
  },
  {
    name: "food-survey-add___fr",
    path: "/fr/food-survey/add",
    meta: add5IDBSjW9HZMeta || {},
    component: () => import("/vercel/path0/pages/food-survey/add.vue")
  },
  {
    name: "food-survey-add___pt",
    path: "/pt/food-survey/add",
    meta: add5IDBSjW9HZMeta || {},
    component: () => import("/vercel/path0/pages/food-survey/add.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "onboarding___en",
    path: "/en/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue")
  },
  {
    name: "onboarding___fr",
    path: "/fr/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue")
  },
  {
    name: "onboarding___pt",
    path: "/pt/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue")
  },
  {
    name: "patients-patientId-analysis-analysisId___en",
    path: "/en/patients/:patientId()/analysis/:analysisId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/[analysisId].vue")
  },
  {
    name: "patients-patientId-analysis-analysisId___fr",
    path: "/fr/patients/:patientId()/analysis/:analysisId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/[analysisId].vue")
  },
  {
    name: "patients-patientId-analysis-analysisId___pt",
    path: "/pt/patients/:patientId()/analysis/:analysisId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/[analysisId].vue")
  },
  {
    name: "patients-patientId-analysis___en",
    path: "/en/patients/:patientId()/analysis",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/index.vue")
  },
  {
    name: "patients-patientId-analysis___fr",
    path: "/fr/patients/:patientId()/analysis",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/index.vue")
  },
  {
    name: "patients-patientId-analysis___pt",
    path: "/pt/patients/:patientId()/analysis",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/index.vue")
  },
  {
    name: "patients-patientId-analysis-last___en",
    path: "/en/patients/:patientId()/analysis/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/last.vue")
  },
  {
    name: "patients-patientId-analysis-last___fr",
    path: "/fr/patients/:patientId()/analysis/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/last.vue")
  },
  {
    name: "patients-patientId-analysis-last___pt",
    path: "/pt/patients/:patientId()/analysis/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/last.vue")
  },
  {
    name: "patients-patientId-analysis-new___en",
    path: "/en/patients/:patientId()/analysis/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/new.vue")
  },
  {
    name: "patients-patientId-analysis-new___fr",
    path: "/fr/patients/:patientId()/analysis/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/new.vue")
  },
  {
    name: "patients-patientId-analysis-new___pt",
    path: "/pt/patients/:patientId()/analysis/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/new.vue")
  },
  {
    name: "patients-patientId-consultations-consultationId___en",
    path: "/en/patients/:patientId()/consultations/:consultationId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/[consultationId].vue")
  },
  {
    name: "patients-patientId-consultations-consultationId___fr",
    path: "/fr/patients/:patientId()/consultations/:consultationId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/[consultationId].vue")
  },
  {
    name: "patients-patientId-consultations-consultationId___pt",
    path: "/pt/patients/:patientId()/consultations/:consultationId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/[consultationId].vue")
  },
  {
    name: "patients-patientId-consultations___en",
    path: "/en/patients/:patientId()/consultations",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/index.vue")
  },
  {
    name: "patients-patientId-consultations___fr",
    path: "/fr/patients/:patientId()/consultations",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/index.vue")
  },
  {
    name: "patients-patientId-consultations___pt",
    path: "/pt/patients/:patientId()/consultations",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/index.vue")
  },
  {
    name: "patients-patientId-consultations-last___en",
    path: "/en/patients/:patientId()/consultations/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/last.vue")
  },
  {
    name: "patients-patientId-consultations-last___fr",
    path: "/fr/patients/:patientId()/consultations/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/last.vue")
  },
  {
    name: "patients-patientId-consultations-last___pt",
    path: "/pt/patients/:patientId()/consultations/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/last.vue")
  },
  {
    name: "patients-patientId-consultations-new___en",
    path: "/en/patients/:patientId()/consultations/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/new.vue")
  },
  {
    name: "patients-patientId-consultations-new___fr",
    path: "/fr/patients/:patientId()/consultations/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/new.vue")
  },
  {
    name: "patients-patientId-consultations-new___pt",
    path: "/pt/patients/:patientId()/consultations/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/new.vue")
  },
  {
    name: "patients-patientId-food-survey-foodSurveyId___en",
    path: "/en/patients/:patientId()/food-survey/:foodSurveyId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/[foodSurveyId].vue")
  },
  {
    name: "patients-patientId-food-survey-foodSurveyId___fr",
    path: "/fr/patients/:patientId()/food-survey/:foodSurveyId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/[foodSurveyId].vue")
  },
  {
    name: "patients-patientId-food-survey-foodSurveyId___pt",
    path: "/pt/patients/:patientId()/food-survey/:foodSurveyId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/[foodSurveyId].vue")
  },
  {
    name: "patients-patientId-food-survey___en",
    path: "/en/patients/:patientId()/food-survey",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/index.vue")
  },
  {
    name: "patients-patientId-food-survey___fr",
    path: "/fr/patients/:patientId()/food-survey",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/index.vue")
  },
  {
    name: "patients-patientId-food-survey___pt",
    path: "/pt/patients/:patientId()/food-survey",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/index.vue")
  },
  {
    name: "patients-patientId-food-survey-last___en",
    path: "/en/patients/:patientId()/food-survey/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/last.vue")
  },
  {
    name: "patients-patientId-food-survey-last___fr",
    path: "/fr/patients/:patientId()/food-survey/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/last.vue")
  },
  {
    name: "patients-patientId-food-survey-last___pt",
    path: "/pt/patients/:patientId()/food-survey/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/last.vue")
  },
  {
    name: "patients-patientId-food-survey-new___en",
    path: "/en/patients/:patientId()/food-survey/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/new.vue")
  },
  {
    name: "patients-patientId-food-survey-new___fr",
    path: "/fr/patients/:patientId()/food-survey/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/new.vue")
  },
  {
    name: "patients-patientId-food-survey-new___pt",
    path: "/pt/patients/:patientId()/food-survey/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/new.vue")
  },
  {
    name: "patients-patientId___en",
    path: "/en/patients/:patientId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/index.vue")
  },
  {
    name: "patients-patientId___fr",
    path: "/fr/patients/:patientId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/index.vue")
  },
  {
    name: "patients-patientId___pt",
    path: "/pt/patients/:patientId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/index.vue")
  },
  {
    name: "patients-patientId-objectives___en",
    path: "/en/patients/:patientId()/objectives",
    component: () => import("/vercel/path0/pages/patients/[patientId]/objectives.vue")
  },
  {
    name: "patients-patientId-objectives___fr",
    path: "/fr/patients/:patientId()/objectives",
    component: () => import("/vercel/path0/pages/patients/[patientId]/objectives.vue")
  },
  {
    name: "patients-patientId-objectives___pt",
    path: "/pt/patients/:patientId()/objectives",
    component: () => import("/vercel/path0/pages/patients/[patientId]/objectives.vue")
  },
  {
    name: "patients___en",
    path: "/en/patients",
    component: () => import("/vercel/path0/pages/patients/index.vue")
  },
  {
    name: "patients___fr",
    path: "/fr/patients",
    component: () => import("/vercel/path0/pages/patients/index.vue")
  },
  {
    name: "patients___pt",
    path: "/pt/patients",
    component: () => import("/vercel/path0/pages/patients/index.vue")
  }
]